jQuery(document).ready(function($){

    $('.mask-nip').inputmask('999-999-99-99', {
    });

    $('.mask-postcode').inputmask('99-999', {
    });

    $('.mask-phone').inputmask({
        regex: "[\\d +-]*",
    });

    $('.mask-email').inputmask({
        alias: "email",
    });

    $('.mask-number').inputmask({
        regex: "\\d*",
    });

    $('.mask-date').inputmask({
        alias: 'datetime',
        inputFormat: 'dd.mm.yyyy',
        placeholder: 'DD.MM.RRRR',
    });

    $('.mask-time').inputmask({
        alias: 'datetime',
        inputFormat: 'HH:MM',
        placeholder: 'GG:MM',
    });

});
